<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <span>Variables</span>
          </v-card-title>
          <v-card-text>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-icon left>business</v-icon>
                  Current Company
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="d-flex justify-space-between align-center">
                    <span>{{ currentCompany }}</span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small @click="copyToClipboard(currentCompany)" v-bind="attrs" v-on="on">
                          <v-icon>content_copy</v-icon>
                        </v-btn>
                      </template>
                      <span>Copy</span>
                    </v-tooltip>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-icon left>people</v-icon>
                  User Information
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="d-flex justify-space-between align-center">
                    <span>{{ userInfo.firstname }} {{ userInfo.surname }}</span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small @click="copyToClipboard(userInfo.firstname + ' ' + userInfo.surname)" v-bind="attrs" v-on="on">
                          <v-icon>content_copy</v-icon>
                        </v-btn>
                      </template>
                      <span>Copy</span>
                    </v-tooltip>
                  </div>
                  <div class="d-flex justify-space-between align-center">
                    <span>{{ userInfo.emailAddress }}</span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small @click="copyToClipboard(userInfo.emailAddress)" v-bind="attrs" v-on="on">
                          <v-icon>content_copy</v-icon>
                        </v-btn>
                      </template>
                      <span>Copy</span>
                    </v-tooltip>
                  </div>
                  <!-- Add more user information as needed -->
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-icon left>business_center</v-icon>
                  Related Organisations
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-for="(org, index) in relatedOrganisations" :key="index" class="d-flex justify-space-between align-center">
                    <span>{{ org.relatedOrganisation.name }}</span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small @click="copyToClipboard(org.relatedOrganisation.name)" v-bind="attrs" v-on="on">
                          <v-icon>content_copy</v-icon>
                        </v-btn>
                      </template>
                      <span>Copy</span>
                    </v-tooltip>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      currentCompany: this.$store.state.currentOrg.name,
      userInfo: this.$store.state.user,
      relatedOrganisations: [],
    };
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        // alert('Text copied to clipboard');
      }).catch(err => {
        console.error('Failed to copy:', err);
        alert('Failed to copy text');
      });
    },
    fetchRelatedOrganisations() {
      const relatedOrgs = localStorage.getItem('relatedOrganisations_2507');
      if (relatedOrgs) {
        const parsedOrgs = JSON.parse(relatedOrgs).data;
        this.relatedOrganisations = parsedOrgs.map(org => ({
          relatedOrganisation: org.relatedOrganisation,
        }));
      }
    }
  },
  created() {
    this.fetchRelatedOrganisations();
  }
};
</script>

<style scoped>
/* Optional: Add some styles to space out the list items and center the content */
li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}
</style>
