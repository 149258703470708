<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="pa-3 no-border" flat>
          <!-- Toolbar -->
          <v-toolbar flat>
            <v-toolbar-title>{{ selectedFolder ? selectedFolder.name : 'My Documents' }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="showFolderModal = true" color="primary" class="mr-6">
              <v-icon left>create_new_folder</v-icon> Create New Folder
            </v-btn>
            <v-btn @click="openNewFileModal" color="primary">
              <v-icon left>add_circle</v-icon> Create New File
            </v-btn>
            <v-btn icon @click="refreshFiles">
              <v-icon>refresh</v-icon>
            </v-btn>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
          </v-toolbar>

          <v-row>
            <!-- Sidebar for folders -->
            <v-col cols="3">
              <v-navigation-drawer permanent width="100%" class="pt-0 no-border" flat>
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content class="d-flex justify-space-between align-center">
                      <v-list-item-title class="title">My Documents</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item-group v-model="selectedFolder" @change="onFolderClick">
                    <v-list-item v-for="(folder, index) in folders" :key="index" :value="folder">
                      <v-list-item-icon>
                        <v-icon>folder</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ folder.name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- New Section for picture -->
                    <v-list-item-content class="d-flex justify-space-between align-center">
                      <v-list-item-title class="title">Images</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item @click="fetchAllPictureUrls" class="cursor-pointer" :value="{ name: 'My Pictures' }">
                      <v-list-item-icon>
                        <v-icon>folder</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>My Pictures</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-navigation-drawer>
            </v-col>

            <!-- Main content area for files -->
            <v-col cols="9">
              <v-card v-if="selectedFolder && selectedFolder.name === 'My Pictures'" class="mb-3">
                <v-row class="align-center justify-space-between">
              <v-col class="d-flex align-center" cols="auto">
                <v-card-title class="mb-0">Images</v-card-title>
              </v-col>

               <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
              <v-col class="d-flex justify-end" cols="auto">
                <v-btn @click="showUploadImageModal = true"  v-bind="attrs" v-on="on">
                  <v-icon left>add_circle</v-icon> 
                </v-btn>
              </v-col>
              </template>
              <span>Add an image</span>
            </v-tooltip>
            </v-row>

                <v-card-text>
                  <v-row>
                    <v-col
                      v-for="(url, index) in pictureUrls"
                      :key="index"
                      cols="3"
                      class="mb-4"
                    >
                      <v-img
                        :src="url"
                        @click="openUrl(url)"
                        aspect-ratio="1"
                        class="pointer thumbnail"
                      ></v-img>
                      <v-list-item-title>
                    <a :href="url" target="_blank" rel="noopener noreferrer" style="text-decoration: none; color: inherit;">
                      <!-- {{ url }} -->
                    </a>
                  </v-list-item-title>
                  <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="copyToClipboard(url)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>content_copy</v-icon>
                    </v-btn>
                  </template>
                  <span>Copy to clipboard</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="deletePicture(url)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-data-table
                v-if="selectedFolder && selectedFolder.name !== 'My Pictures'"
                :headers="headers"
                :items="filteredFiles"
                :search="search"
                class="elevation-0 no-border"
              >
                <template v-slot:item.filename="{ item }">
                  <span @click="editFile(item)" class="file-name" style="cursor: pointer;">
                    {{ item.filename }}
                  </span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-2" @click="editFile(item)" v-bind="attrs" v-on="on">edit</v-icon>
                </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                  <v-icon small @click="deleteFile(item)" v-bind="attrs" v-on="on">delete</v-icon>
                </template>
                  <span>Delete</span>
                </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                  <v-icon small @click="shareFile(item)" v-bind="attrs" v-on="on"> share  </v-icon>
                </template>
                  <span>Share</span>
                </v-tooltip>

                 <!-- Modal for sharing documents -->

                 <v-dialog v-model="showShareDialog" max-width="500px">
    <v-card>
      <v-card-title>Share Document</v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="selectedCompanyToShare"
          :items="relatedOrganisations"
          item-text="name"
          item-value="id"
          label="Select Company to Share With"
          clearable
          :search-input.sync="vsearch"
          item-color="red"
        >
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="isShared(item.id)">
                <v-icon color="green">check</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="shareDocument">Share</v-btn>
        <v-btn color="secondary" @click="showShareDialog = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
             
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
      
    <!-- Modal for creating/editing files -->
    <v-dialog v-model="showFileModal" max-width="95vw" class="file-modal">
      <v-card>
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="d-flex align-center">

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">

            <v-btn @click="saveFile" v-bind="attrs" v-on="on">
              <v-icon > save</v-icon>
            </v-btn>
            </template>
                <span>Save</span>
              </v-tooltip>

              <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
            <v-btn @click="loadFile" v-bind="attrs" v-on="on" >
              <v-icon class="material-icons">folder_open</v-icon>
            </v-btn>
          </template>
            <span>Upload file</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
            <v-btn @click="downloadFile" v-on="on" v-bind="attrs">
              <v-icon class="material-icons">download</v-icon>
            </v-btn>
          </template>
            <span>Download </span>
            </v-tooltip>

          </div>
          <span class="headline">Create/Edit File</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="filename" label="File Name" outlined></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="editor-container">
                <div class="editor-toolbar">

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon @click="toggleBold" v-bind="attrs" v-on="on">
                        <v-icon class="material-icons">format_bold</v-icon>
                      </v-btn>
                    </template>
                    <span>Bold</span>
                  </v-tooltip>
                      
                  <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="toggleItalic" v-bind="attrs" v-on="on">
                      <v-icon class="material-icons">format_italic</v-icon>
                    </v-btn>
                  </template>
                  <span>Italics</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="toggleUnderline" v-bind="attrs" v-on="on">
                      <v-icon class="material-icons" >format_underlined</v-icon>
                    </v-btn>
                  </template>
                  <span>Underline</span>
                </v-tooltip>

                <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon small>text_format</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="toggleStrikethrough">
                    <v-list-item-icon>
                      <v-icon small>strikethrough_s</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>Strikethrough</v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="toggleHeading(1)">
                    <v-list-item-icon>
                      <v-icon small>looks_one</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>Heading 1</v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="toggleHeading(2)">
                    <v-list-item-icon>
                      <v-icon small>looks_two</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>Heading 2</v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="toggleHeading(3)">
                    <v-list-item-icon>
                      <v-icon small>looks_3</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>Heading 3</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon small>format_align_left</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="editor.chain().focus().setTextAlign('left').run()">
                    <v-list-item-icon>
                      <v-icon small>format_align_left</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>Align Left</v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="editor.chain().focus().setTextAlign('center').run()">
                    <v-list-item-icon>
                      <v-icon small>format_align_center</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>Align Center</v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="editor.chain().focus().setTextAlign('right').run()">
                    <v-list-item-icon>
                      <v-icon small>format_align_right</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>Align Right</v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="editor.chain().focus().setTextAlign('justify').run()">
                    <v-list-item-icon>
                      <v-icon small>format_align_justify</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>Align Justify</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>

                  <v-tooltip bottom> 
                    <template v-slot:activator="{ on, attrs }"> 
                  <v-btn @click="toggleBulletList" v-bind="attrs" v-on="on"><v-icon class="material-icons">format_list_bulleted</v-icon></v-btn>
                </template>  <span>List Item </span> </v-tooltip>

                <v-tooltip bottom> 
                  <template v-slot:activator="{ on, attrs }"> 
                  <v-btn @click="toggleOrderedList" v-bind="attrs" v-on="on"><v-icon class="material-icons">format_list_numbered</v-icon></v-btn>
                </template>  <span>Numbered List </span> </v-tooltip>
                 
                  <!-- <input 
                    type="color" 
                    @input="editor.chain().focus().setColor($event.target.value).run()" 
                    :value="editor.getAttributes('textStyle').color || '#000000'"
                  /> -->

                  <v-select
                    :items="fontFamilies"
                    item-text="label"
                    item-value="action"
                    label="Font Family"
                    @change="handleFontAction"
                    outlined
                    dense
                    class="ml-3 mt-6 font-family-select"
                  >
                    <template v-slot:selection="{ item }">
                      <v-icon left>{{ item.icon }}</v-icon>
                      {{ item.label }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-icon left>{{ item.icon }}</v-icon>
                      {{ item.label }}
                    </template>
                </v-select>

                  <v-tooltip bottom> 
                    <template v-slot:activator="{ on, attrs }"> 
                  <v-btn @click="editor.chain().focus().toggleHighlight().run()"  v-bind="attrs" v-on="on">
                    <v-icon class="material-icons">highlight</v-icon>
                  </v-btn> </template>  <span>Highlight </span> </v-tooltip>

                  <v-tooltip bottom> 
                    <template v-slot:activator="{ on, attrs }"> 
                  <v-btn @click="setLink" v-bind="attrs" v-on="on">
                    <v-icon class="material-icons">link</v-icon>
                  </v-btn>  </template>  <span>Link </span> </v-tooltip>

                  <v-tooltip bottom> 
                    <template v-slot:activator="{ on, attrs }"> 
                  <v-btn @click="unsetLink" v-bind="attrs" v-on="on">
                    <v-icon class="material-icons">link_off</v-icon>
                  </v-btn>  </template>  <span>Unlink </span> </v-tooltip>

                  <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon small>code</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="toggleCodeBlock">
                    <v-list-item-icon>
                      <v-icon small>code</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>Insert Codeblock</v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="setHorizontalRule">
                    <v-list-item-icon>
                      <v-icon small>remove</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>Horizontal Rule</v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="undo">
                    <v-list-item-icon>
                      <v-icon small>undo</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>Undo</v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="redo">
                    <v-list-item-icon>
                      <v-icon small>redo</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>Redo</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>

                <v-tooltip bottom> 
                  <template v-slot:activator="{ on, attrs }"> 
                  <v-btn @click="addImage" v-bind="attrs" v-on="on"> <v-icon class="material-icons">image</v-icon></v-btn>
                </template>  <span>Add Image </span> </v-tooltip>


                  <v-tooltip bottom> 
                    <template v-slot:activator="{ on, attrs }"> 
                  <v-btn @click="editor.chain().focus().toggleTaskList().run()" :class="{ 'is-active': editor.isActive('taskList') }" v-bind="attrs" v-on="on">
                    <v-icon class="material-icons">checklist</v-icon> 
                  </v-btn> </template>  <span>Checklist </span> </v-tooltip>

                  <v-tooltip bottom> 
                    <template v-slot:activator="{ on, attrs }"> 
                  <v-btn @click="editor.chain().focus().liftListItem('taskItem').run()" :disabled="!editor.can().liftListItem('taskItem')" v-bind="attrs" v-on="on">
                    <v-icon class="material-icons">check_box</v-icon>
                  </v-btn>  </template>  <span>Check Box </span> </v-tooltip>


                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon @click="showVariablesModal = true" v-bind="attrs" v-on="on">
                        <v-icon>view_list</v-icon>
                      </v-btn>
                    </template>
                    <span>Show Variables</span>
                  </v-tooltip>  -->

                  <v-tooltip bottom> 
                    <template v-slot:activator="{ on, attrs }"> 
                  <v-btn id="add" @click="addVideo" v-bind="attrs" v-on="on">
                    <v-icon class="material-icons">videocam</v-icon>
                  </v-btn>  </template>  <span>Add Video </span> </v-tooltip> 



                    <!-- Modal for showing variables -->

                  <v-dialog v-model="showVariablesModal" max-width="500px">
                    <variables />
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" text @click="showVariablesModal = false">Close</v-btn>
                    </v-card-actions>
                  </v-dialog>


            <v-select
            :items="tableActions"
            item-text="label"
            item-value="action"
            label="Table Options"
            @change="handleTableAction"
            outlined
            dense
            class="ml-1 mt-5"
          >
            <template v-slot:selection="{ item }">
              <v-icon left>{{ item.icon }}</v-icon>
              {{ item.label }}
            </template>
            <template v-slot:item="{ item }">
              <v-icon left>{{ item.icon }}</v-icon>
              {{ item.label }}
            </template>
          </v-select>

          <v-select
        :items="placeholderItems"
        label="Select Placeholder"
        item-value="value"
        item-text="text"
        v-model="selectedPlaceholder"
        @change="updateEditorContent"
        outlined
        dense
        class="ml-1 mt-5"
      >
        <template v-slot:item="data">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ data.item.text }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click.stop="copyPlaceholder(data.item.value)">
                <v-icon>content_copy</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-select>

          <!-- <v-select
            v-model="selectedRelatedOrg"
            :items="relatedOrganisations"
            item-text="name"
            item-value="name"
            label="Select Related Organization"
            @change="updateRelatedOrgPlaceholder"
          ></v-select> -->


                </div>
                <editor-content :editor="editor" class="editor-content" />

                <!-- Character count display -->
                <div class="character-count">
                  Characters: {{ characterCount }}
                </div>

              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-list>
                <v-subheader class="black-text">Currently Viewing/Editing</v-subheader>
                <v-list-item v-for="user in activeUsers" :key="user.id">
                  <v-list-item-content>
                    <v-list-item-title>{{ user.name }} - {{ user.status }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <!-- <v-btn @click="saveFile" icon color="black">
            <i class="material-icons">save</i>
          </v-btn>
          <v-btn @click="loadFile" icon color="black">
            <i class="material-icons">folder_open</i>
          </v-btn>
          <v-btn @click="downloadFile" icon color="black">
            <i class="material-icons">download</i>
          </v-btn> -->
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closeFileModal">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal for creating new folders -->
    <v-dialog v-model="showFolderModal" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Create New Folder</span>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="newFolderName" label="Folder Name" outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="createFolder">Create</v-btn>
          <v-btn color="red darken-1" text @click="closeFolderModal">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal for uploading images -->
    <v-dialog v-model="showUploadImageModal" max-width="500px" @open="showLoader = true"  @close="showLoader = false" >
      <v-card>
        <v-card-title>
          <span class="headline">Upload Image</span>
        </v-card-title>
        <v-card-text>
          <v-btn @click="handlePictureUpload" color="primary">Select and Upload Image</v-btn>

          <v-progress-circular
            v-if="showLoader"
            indeterminate
            color="primary"
            size="40"
            class="d-flex justify-center"
          ></v-progress-circular>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="showUploadImageModal = false">Close</v-btn>
          <v-btn @click="savePicture" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { EditorContent } from '@tiptap/vue-2'
import { Editor } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import Image from '@tiptap/extension-image'
import Text from '@tiptap/extension-text'
import Paragraph from '@tiptap/extension-paragraph'
import Dropcursor from '@tiptap/extension-dropcursor'
import Document from '@tiptap/extension-document'
import TextAlign from '@tiptap/extension-text-align'
import Blockquote from '@tiptap/extension-blockquote'
import { BubbleMenu } from '@tiptap/extension-bubble-menu'
import { BubbleMenu as BubbleMenuComponent } from '@tiptap/vue-2'
import CharacterCount from '@tiptap/extension-character-count'
import Color from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import FontFamily from '@tiptap/extension-font-family';
import Highlight from '@tiptap/extension-highlight';
import Link from '@tiptap/extension-link';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Youtube from '@tiptap/extension-youtube'
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import variables from '../Management/Variables.vue';
import Focus from '@tiptap/extension-focus'

export default {
  components: {
    EditorContent,
    // BubbleMenu: BubbleMenuComponent,
    variables
  },
  data() {
    return {
      showLoader: false,
      tableActions: [
        { label: 'Insert Table', icon: 'mdi-table_chart', action: 'insertTable' },
        { label: 'Add Column Before', icon: 'mdi-add_box ', action: 'addColumnBefore' },
        { label: 'Add Column After', icon: 'mdi-insert_chart', action: 'addColumnAfter' },
        { label: 'Delete Column', icon: 'mdi-table-column-remove', action: 'deleteColumn' },
        { label: 'Add Row Before', icon: 'mdi-table-row-plus-before', action: 'addRowBefore' },
        { label: 'Add Row After', icon: 'mdi-table-row-plus-after', action: 'addRowAfter' },
        { label: 'Delete Row', icon: 'mdi-table-row-remove', action: 'deleteRow' },
        { label: 'Delete Table', icon: 'mdi-table-remove', action: 'deleteTable' },
      ],

      fontFamilies: [
        { label: 'Comic Sans', icon: 'mdi-format-font', action: 'Comic Sans MS, Comic Sans' },
        { label: 'Inter', icon: 'mdi-format-font', action: 'Inter' },
        { label: 'Serif', icon: 'mdi-format-font', action: 'serif' },
        { label: 'Monospace', icon: 'mdi-format-font', action: 'monospace' },
        { label: 'Cursive', icon: 'mdi-format-font', action: 'cursive' },
        { label: 'Unset font family', icon: 'mdi-format-font', action: 'unset' },
      ],


      //Placeholders Menu 
      selectedPlaceholder: null,
      placeholderItems: [
        { text: '{date}', value: '{date}' },
        { text: '{time}', value: '{time}' },
        { text: '{datetime}', value: '{datetime}' },
        { text: '{username}', value: '{username}' },
        { text: '{userSurname}', value: '{userSurname}' },
        { text: '{userTelephone}', value: '{userTelephone}' },
        { text: '{CurrentOrg}', value: '{CurrentOrg}' },
        { text: '{CurrentOrgAddress}', value: '{CurrentOrgAddress}' },
        { text: '{CurrentOrgRegNumber}', value: '{CurrentOrgRegNumber}' },
        { text: '{CurrentOrgCountry}', value: '{CurrentOrgCountry}' },
        { text: '{CurrentOrgtelephoneNumber}', value: '{CurrentOrgtelephoneNumber}' },
        { text: '{CurrentOrgemailAddress}', value: '{CurrentOrgemailAddress}' },
        { text: '{CurrentOrgtaxNumber}', value: '{CurrentOrgtaxNumber}' },
        { text: '{CurrentOrglogliveCode}', value: '{CurrentOrglogliveCode}' },
        { text: '{email}', value: '{email}' },
        { text: '{RelatedOrg}', value: '{RelatedOrg}' },
        { text: '{RelatedOrgCountry}', value: '{RelatedOrgCountry}' },
        { text: '{RelatedOrgVat}', value: '{RelatedOrgVat}' },
        { text: '{RelatedOrgregistrationNumber}', value: '{RelatedOrgregistrationNumber}' },
        { text: '{RelatedOrgtelephoneNumber}', value: '{RelatedOrgtelephoneNumber}' },
        { text: '{RelatedOrgemailAddress}', value: '{RelatedOrgemailAddress}' },
        { text: '{RelatedOrgtaxNumber}', value: '{RelatedOrgtaxNumber}' },
        { text: '{RelatedOrglogliveCode}', value: '{RelatedOrglogliveCode}' },
        { text: '{RelatedOrgAddress1}', value: '{RelatedOrgAddress1}'},
        {text: '{RelatedOrgAddress2}', value: '{RelatedOrgAddress2}'}
      ],

      //variables
      showVariablesModal: false,
      relatedOrganisations: [],
      selectedRelatedOrg: '',

      //Sharing data
      showShareDialog: false,
      currentFileToShare: null,
      selectedCompanyToShare: null,
      

      showFileModal: false,
      showFolderModal: false,
      showUploadImageModal: false,
      filename: '',
      content: '',
      files: [],
      vsearch: '',
      search: '',
      selectedImageFile: null,
      selectedFolder: null,
      newFolderName: '',
      activeUsers: [],
      userUpdateKey: 0,
      websocket: null,
      pictureUrls: [],
      folders: [
        { name: 'My Documents', icon: 'folder' },
        { name: 'My Pictures', icon: 'folder' } 
      ],
      headers: [
        { text: 'Name', value: 'filename', sortable: true },
        { text: 'Last Modified', value: 'lastModified', sortable: true },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editor: null,
      characterCount: 0,
    };
  },
  computed: {
    filteredFiles() {
      const searchLowerCase = this.search.toLowerCase();
      return this.files.filter(file => {
        return (
          file.filename.toLowerCase().includes(searchLowerCase) &&
          (!this.selectedFolder || file.folder === this.selectedFolder.name)
        );
      });
    }
  },
  methods: {

    copyPlaceholder(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$toast.open('Placeholder copied to clipboard');
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    },

  //Functions for sharing Documents 
  shareFile(item) {
    this.currentFileToShare = item;
    this.showShareDialog = true;
  },
  isShared(orgId) {
      const org = this.relatedOrganisations.find(o => o.id === orgId);
      return org && org.shared;
    },
    shareDocument() {
    const selectedCompanyId = this.selectedCompanyToShare;
    const documentId = this.currentFileToShare.id;

    // Find the related organization from the local state
    const relatedOrg = this.relatedOrganisations.find(org => org.id === selectedCompanyId);

    if (relatedOrg) {
      this.selectedRelatedOrg = relatedOrg;

      this.$API.shareDocument({
        documentId,
        sharedWithCompanyId: selectedCompanyId,
      }).then(() => {
        this.showShareDialog = false;
        relatedOrg.shared = true; // Set shared property to true
        this.$toast.success('Document shared successfully');

        // Save shared state to localStorage
        const sharedOrgs = JSON.parse(localStorage.getItem('sharedOrganisations')) || {};
        sharedOrgs[selectedCompanyId] = true;
        localStorage.setItem('sharedOrganisations', JSON.stringify(sharedOrgs));
      }).catch((error) => {
        console.error('Error sharing document:', error);
        this.$toast.error('Error sharing document');
      });
    } else {
      console.error('Selected related organization not found');
      this.$toast.error('Selected related organization not found');
    }
  },

    //Methods to fetch all related organisation 
    fetchRelatedOrganisations() {
      const currentOrgId = this.$store.state.currentOrg.id;
      const relatedOrgs = localStorage.getItem(`relatedOrganisations_${currentOrgId}`);
      const sharedOrgs = JSON.parse(localStorage.getItem('sharedOrganisations')) || {};
      if (relatedOrgs) {
        const parsedOrgs = JSON.parse(relatedOrgs).data;
        this.relatedOrganisations = parsedOrgs.map(org => ({
          id: org.relatedOrganisation.id,
          name: org.relatedOrganisation.name,
          countryCode: org.relatedOrganisation.countryCode,
          vatNumber: org.relatedOrganisation.vatNumber,
          telephoneNumber: org.relatedOrganisation.telephoneNumber,
          registrationNumber: org.relatedOrganisation.registrationNumber,
          emailAddress: org.relatedOrganisation.emailAddress,
          taxNumber: org.relatedOrganisation.taxNumber,
          logliveCode: org.relatedOrganisation.logliveCode,
          addressLine1: org.relatedOrganisation.addressLine1,
          addressLine2: org.relatedOrganisation.addressLine2,
          shared: sharedOrgs[org.relatedOrganisation.id] || false

        }));
      }
    },
    updateRelatedOrgPlaceholder(name) {
    if (this.editor) {
      let content = this.editor.getHTML();
      content = content.replace(/{RelatedOrg}/g, name);
      this.editor.commands.setContent(content);
    }
  },

  //   shareFile(item) {
  //   this.currentFileToShare = item;
  //   this.showShareDialog = true;
  // },

  // shareDocument() {
  //   const selectedCompanyId = this.selectedCompanyToShare;
  //   const documentId = this.currentFileToShare.id;

  //   this.$API.shareDocument({
  //     documentId,
  //     sharedWithCompanyId: selectedCompanyId,
  //   }).then(() => {
  //     this.showShareDialog = false;
  //     this.$toast.success('Document shared successfully');
  //   }).catch((error) => {
  //     console.error('Error sharing document:', error);
  //     this.$toast.error('Error sharing document');
  //   });
  // },



    insertTable() {
      this.editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: false }).run();
    },

    handleTableAction(action) {
      switch (action) {
        case 'insertTable':
          this.insertTable();
          break;
        case 'addColumnBefore':
          this.editor.chain().focus().addColumnBefore().run();
          break;
        case 'addColumnAfter':
          this.editor.chain().focus().addColumnAfter().run();
          break;
        case 'deleteColumn':
          this.editor.chain().focus().deleteColumn().run();
          break;
        case 'addRowBefore':
          this.editor.chain().focus().addRowBefore().run();
          break;
        case 'addRowAfter':
          this.editor.chain().focus().addRowAfter().run();
          break;
        case 'deleteRow':
          this.editor.chain().focus().deleteRow().run();
          break;
        case 'deleteTable':
          this.editor.chain().focus().deleteTable().run();
          break;
      }},

      handleFontAction(action) {
      switch (action) {
        case 'Comic Sans MS, Comic Sans':
          this.editor.chain().focus().setFontFamily('Comic Sans MS, Comic Sans').run();
          break;
        case 'Inter':
          this.editor.chain().focus().setFontFamily('Inter').run();
          break;
        case 'serif':
          this.editor.chain().focus().setFontFamily('serif').run();
          break;
        case 'monospace':
          this.editor.chain().focus().setFontFamily('monospace').run();
          break;
        case 'cursive':
          this.editor.chain().focus().setFontFamily('cursive').run();
          break;
        case 'unset':
          this.editor.chain().focus().unsetFontFamily().run();
          break;
        default:
          console.warn('Unsupported font family:', action);
      }
    },


    async fetchFilesAndFolders() {
      try {
        const response = await this.$API.markdownDocumentGetFilesAndFolders({
          userId: this.$store.state.user.id,
          organisationId: this.$store.state.currentOrg.id,
        });

        if (response && response.files && response.folders) {
          this.files = response.files;
          this.folders = response.folders;
        } else {
          throw new Error('Unexpected response format');
        }
      } catch (error) {
        console.error('Error fetching files and folders:', error);
        alert('Error fetching files and folders');
      }
    },

    openNewFileModal() {
    this.clearEditor(); // Clear the editor and filename
    this.showFileModal = true; // Show the file modal
  },
    clearEditor() {
    this.filename = ''; // Clear the filename
    if (this.editor) {
      this.editor.commands.clearContent(); // Clear the editor content
    }
  },

    async saveFile() {
      const contentWithValues = this.replacePlaceholders(this.editor.getHTML(), false); //Change to true to view actual values of placeholders
      console.log('Saving file:', this.filename);
      if (this.filename && this.editor.getHTML()) {
        try {
          const response = await this.$API.markdownDocumentUpload({
            filename: this.filename,
            content:  contentWithValues,
            userId: this.$store.state.user.id,
            organisationId: this.$store.state.currentOrg.id,
            folder: this.selectedFolder ? this.selectedFolder.name : 'My Documents',
          });

          if (response && response.document) {
            const { document } = response;
            const fileIndex = this.files.findIndex(file => file.id === document.id);
            if (fileIndex !== -1) {
              // Update existing file in the list
              this.$set(this.files, fileIndex, {
                id: document.id,
                filename: document.filename,
                // s3Key: document.s3Key,
                lastModified: new Date(document.updatedAt).toLocaleString(),
                folder: document.folder,
                content: document.content,
              });
            } else {
              // Add new file to the list
              this.files.push({
                id: document.id,
                filename: document.filename,
                s3Key: document.s3Key,
                lastModified: new Date(document.updatedAt).toLocaleString(),
                folder: document.folder,
                content: document.content,
              });
            }
            this.showFileModal = false;
            this.updateViewingStatus('idle');
          } else {
            throw new Error('Unexpected response format');
          }
        } catch (error) {
          console.error('Error saving file:', error);
          alert('Error saving file');
        }
      } else {
        alert('Please enter a file name and content');
      }
    },
    downloadFile() {
      if (this.filename && this.editor.getHTML()) {
        const blob = new Blob([this.editor.getHTML()], { type: 'text/html' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${this.filename}.html`;
        link.click();
      } else {
        alert('Please enter a file name and content');
      }
    },
    loadFile(event) {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = '.html';
      input.onchange = async (e) => {
        const file = e.target.files[0];
        if (file) {
          this.filename = file.name.replace('.html', '');
          const content = await file.text();
          this.editor.commands.setContent(content);
          this.saveFile();
        }
      };
      input.click();
    },
    editFile(file) {
      this.filename = file.filename;
      this.editor.commands.setContent(file.content);
      this.selectedFolder = this.folders.find(folder => folder.name === file.folder);
      this.showFileModal = true;
      this.updateViewingStatus('editing');
    },

    async deleteFile(file) {
      const index = this.files.indexOf(file);
      if (index !== -1) {
        this.files.splice(index, 1);
      }

      try {
        const response = await this.$API.markdownDocumentDelete({
          filename: file.filename,
          folder: file.folder,
          userId: this.$store.state.user.id,
          organisationId: this.$store.state.currentOrg.id,
        });

        if (response.status === 200) {
          console.log('File deleted successfully');
        } else {
          throw new Error('Failed to delete file');
        }
      } catch (error) {
        console.error('Error deleting file', error);
      }
    },

    async uploadPicture(pictureFile) {
      try {
        const formData = new FormData();
        formData.append('picture', pictureFile);
        formData.append('userId', this.$store.state.user.id);
        formData.append('organisationId', this.$store.state.currentOrg.id);
        formData.append('folder', 'My Pictures');

        const response = await this.$API.pictureUpload(formData);
        const pictureUrl = response.url;
        this.pictureUrls.push(pictureUrl);
        console.log('Picture uploaded successfully. URL:', pictureUrl);

        // this.pictureUrls.push(pictureFile);
        // localStorage.setItem('pictureUrls', JSON.stringify(this.pictureUrls));
        // console.log('Added new picture and updated local storage');

        this.showUploadImageModal = false;
      } catch (error) {
        console.error('Error uploading picture:', error);
      }
    },

  async fetchAllPictureUrls() {
  try {
    const response = await this.$API.getPictureUrl({
      organisationId: this.$store.state.currentOrg.id,
      // folder: 'My Pictures',
    });
    console.log('API response:', response);

    this.pictureUrls = response.urls || [];
    console.log('Updated pictureUrls:', this.pictureUrls);

    // Store pictureUrls in local storage
    // localStorage.setItem('pictureUrls', JSON.stringify(this.pictureUrls));
    // console.log('Stored pictureUrls in local storage');
  } catch (error) {
    console.error('Error fetching picture URLs:', error);
    this.$toast.error('Failed to fetch picture URLs');
  }
},

    copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(() => {
      // alert('Link copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy: ', err);
      alert('Failed to copy link');
    });
    },

  async deletePicture(url) {
  
  const organisationId = this.$store.state.currentOrg.id; 
  const s3Key = url; // Use the URL directly as the s3Key

  console.log('Deleting picture with s3Key:', s3Key);
  console.log('Organisation ID:', organisationId);

  try {
    // Call the backend API to delete the picture
    await this.$API.deletePicture({ s3Key, organisationId });

    // Remove the picture from the local state
    this.pictureUrls = this.pictureUrls.filter(pictureUrl => pictureUrl !== url);

    this.$toast.success('Picture deleted successfully');

    this.pictureUrls = this.pictureUrls.filter(pictureUrl => pictureUrl !== url);
  localStorage.setItem('pictureUrls', JSON.stringify(this.pictureUrls));
  console.log('Deleted picture and updated local storage');


  } catch (error) {
    console.error('Error deleting picture:', error);
    this.$toast.error('Failed to delete picture');
  }
},

    openUrl(url) {
      window.open(url, '_blank');
    },
    handlePictureUpload() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange = (e) => {
        const file = e.target.files[0];
        if (file) {
          this.selectedImageFile = file; // Update the selected image file state
          this.showLoader = true; // Show the loader after file selection
        }
      };
      input.click();
    },

    async savePicture() {
      if (this.selectedImageFile) {
        try {
          await this.uploadPicture(this.selectedImageFile); // Call the upload function
        } catch (error) {
          console.error('Error uploading picture:', error);
          // Handle upload error if necessary
        } finally {
          this.showLoader = false; // Hide the loader
          this.showUploadImageModal = false; // Close the modal
          this.selectedImageFile = null; // Reset the selected image file
        }
      }
    },

    onFolderClick(folder) {
      this.selectedFolder = folder;
      if (folder.name === 'My Pictures') {
        this.fetchAllPictureUrls();
      }
    },

    openImageInNewTab(url) {
      window.open(url, '_blank');
    },

    closeFileModal() {
      this.showFileModal = false;
      this.updateViewingStatus('idle');
    },
    closeFolderModal() {
      this.showFolderModal = false;
    },
    async createFolder() {
      if (this.newFolderName) {
        try {
          const response = await this.$API.markdownDocumentCreate({
            folderName: this.newFolderName,
            userId: this.$store.state.user.id,
            organisationId: this.$store.state.currentOrg.id,
          });

          if (response && response.folder) {
            this.folders.push({
              name: this.newFolderName,
              icon: 'mdi-folder',
            });
            this.newFolderName = '';
            this.showFolderModal = false;
          } else {
            throw new Error('Failed to create folder');
          }
        } catch (error) {
          console.error('Error creating folder:', error);
          alert('Error creating folder');
        }
      } else {
        alert('Please enter a folder name');
      }
    },
    updateViewingStatus(status) {
      if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
        this.websocket.send(
          JSON.stringify({
            action: "join",
            room: "file_" + this.filename,
            user: {
              id: this.$store.state.user.id,
              name: this.$store.state.user.firstname + " " + this.$store.state.user.surname,
              avatar: this.$store.state.user.avatar,
              company: this.$store.state.currentOrg.name,
              status: status,
            },
          })
        );
      }
    },

    addVideo() {
      const url = prompt('Enter YouTube URL')

      this.editor.commands.setYoutubeVideo({
        src: url,
        width: Math.max(320, parseInt(this.width, 10)) || 640,
        height: Math.max(180, parseInt(this.height, 10)) || 480,
      })
    },
    // setFontFamily(fontFamily) {
    //   this.editor.chain().focus().setFontFamily(fontFamily).run();
    // },
    listenToViewingStatus() {
      if (this.websocket) {
        this.websocket.onmessage = (data) => {
          let message = JSON.parse(data.data);
          if (message.type === "users" && message.room === "file_" + this.filename) {
            this.activeUsers = message.data;
            this.userUpdateKey++;
          }
        };
      }
    },
    filterFiles() {
      // This method will be called when a folder is selected, if needed
    },
    refreshFiles() {
      // This method can be used to refresh the file list
      this.fetchFilesAndFolders();
    },
    connectWebSocket() {
      const reconnectInterval = 100;
      const connect = () => {
        this.websocket = new WebSocket(`wss://ws.loglive.ai/?api-key=${process.env.VUE_APP_WEBSOCKET_KEY}`);
        this.websocket.onopen = () => {
          console.log("WebSocket connected", new Date());
          this.updateViewingStatus('viewing');
        };
        this.websocket.onerror = (e) => {
          console.log("WebSocket error", e);
        };
        this.websocket.onclose = () => {
          console.log("WebSocket closed");
          setTimeout(connect, reconnectInterval);
        };
        this.websocket.onmessage = (data) => {
          let message = JSON.parse(data.data);
          if (message.type === "users" && message.room === "documents") {
            this.activeUsers = message.data;
            this.userUpdateKey++;
          }
        };
      };
      connect();
    },
    toggleBold() {
      this.editor.chain().focus().toggleBold().run();
    },
    insertTaskList() {
      this.editor.chain().focus().insertTaskList().run();
    },
    toggleTaskItem() {
      this.editor.chain().focus().toggleTaskItem().run();
    },

    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run();
    },
    toggleUnderline() {
      this.editor.chain().focus().toggleUnderline().run();
    },
    toggleStrikethrough() {
      this.editor.chain().focus().toggleStrike().run();
    },
    toggleHighlight() {
      this.editor.chain().focus().toggleHighlight().run();
    },
    toggleHeading(level) {
      this.editor.chain().focus().toggleHeading({ level }).run();
    },
    setParagraph() {
      this.editor.chain().focus().setParagraph().run();
    },
    toggleBulletList() {
      this.editor.chain().focus().toggleBulletList().run();
    },
    toggleOrderedList() {
      this.editor.chain().focus().toggleOrderedList().run();
    },
    addImage() {
      const url = window.prompt('URL')

      if (url) {
        this.editor.chain().focus().setImage({ src: url }).run()}}, 

    toggleBlockquote() {
      this.editor.chain().focus().toggleBlockquote().run();
    },
    toggleCodeBlock() {
      this.editor.chain().focus().toggleCodeBlock().run();
    },
    setHorizontalRule() {
      this.editor.chain().focus().setHorizontalRule().run();
    },
    undo() {
      this.editor.chain().focus().undo().run();
    },
    redo() {
      this.editor.chain().focus().redo().run();
    },
    setColor(color) {
      this.editor.chain().focus().setColor(color).run();
    },
    setLink() {
      const url = prompt('Enter URL');
      if (url) {
        this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
      }
    },
    unsetLink() {
      this.editor.chain().focus().unsetLink().run();
    },

    replacePlaceholders(content, showPlaceholders = true) {
    const placeholders = {
      '{date}': new Date().toLocaleDateString(),
      '{time}': new Date().toLocaleTimeString(),
      '{datetime}': new Date().toLocaleString(),
      '{username}': this.$store.state.user.firstname,
      '{userSurname}': this.$store.state.user.surname,
      '{userTelephone}': this.$store.state.user.telephoneNumber,
      '{CurrentOrg}': this.$store.state.currentOrg.name,
      '{CurrentOrgAddress}': this.$store.state.currentOrg.address,
      '{CurrentOrgRegNumber}': this.$store.state.currentOrg.registrationNumber,
      '{CurrentOrgCountry}': this.$store.state.currentOrg.countryCode,
      '{CurrentOrgtelephoneNumber}': this.$store.state.currentOrg.telephoneNumber,
      '{CurrentOrgemailAddress}': this.$store.state.currentOrg.emailAddress,
      '{CurrentOrgtaxNumber}': this.$store.state.currentOrg.taxNumber,
      '{CurrentOrglogliveCode}': this.$store.state.currentOrg.logliveCode,
      '{email}': this.$store.state.user.emailAddress,
      '{RelatedOrg}': this.selectedRelatedOrg?.name || 'No related org selected',
      '{RelatedOrgCountry}': this.selectedRelatedOrg?.countryCode || 'No related org selected',
      '{RelatedOrgVat}': this.selectedRelatedOrg?.vatNumber || 'No related org Selected',
      '{RelatedOrgregistrationNumber}': this.selectedRelatedOrg?.registrationNumber || 'No related org Selected',
      '{RelatedOrgtelephoneNumber}': this.selectedRelatedOrg?.telephoneNumber || 'No related org Selected',
      '{RelatedOrgemailAddress}': this.selectedRelatedOrg?.emailAddress || 'No related org Selected',
      '{RelatedOrgtaxNumber}': this.selectedRelatedOrg?.taxNumber || 'No related org Selected',
      '{RelatedOrglogliveCode}': this.selectedRelatedOrg?.logliveCode || 'No related org Selected',
      '{RelatedOrgAddress1}': this.selectedRelatedOrg?.addressLine1 || 'No related org Selected',
      '{RelatedOrgAddress2}': this.selectedRelatedOrg?.addressLine2 || 'No related org Selected',
    };

    if (showPlaceholders) {
      return content;
    }
    return content.replace(/{\w+}/g, (match) => placeholders[match] || match);
  },

  updateEditorContent() {
    if (this.editor) {
      const content = this.editor.getHTML();
      const updatedContent = this.replacePlaceholders(content);
      this.editor.commands.setContent(updatedContent);
    }
  },
  },

  created() {

    this.fetchAllPictureUrls();

    this.fetchRelatedOrganisations();

    // const storedUrls = localStorage.getItem('pictureUrls');
    // if (storedUrls) {
    //   this.pictureUrls = JSON.parse(storedUrls);
    //   console.log('Loaded pictureUrls from local storage:', this.pictureUrls);
    // } else {
    //   this.fetchAllPictureUrls();
    // }
  },
  watch: {
    showFileModal(newVal) {
      if (newVal) {
        this.updateViewingStatus('viewing');
        this.listenToViewingStatus();
      }
    },
    selectedFolder(newVal) {
      console.log('Selected folder:', newVal);
    },

    characterCount(newVal) {
      // console.log(`Current character count: ${newVal}`);
    },
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Image,
        Link.configure({
          openOnClick: true, // Optional: Do not open links on click
          autolink: true,     // Optional: Automatically create links
          linkOnPaste: true,  // Optional: Create links from pasted text
          HTMLAttributes: {
            target: '_blank',
            rel: 'noopener noreferrer',
          },
        }),
        Highlight.configure({
          multicolor: true, 
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph'], 
        }),
        Placeholder.configure({
          placeholder: 'Write something...',
        }),
        Underline,
        Focus.configure({
          className: 'has-focus',
          mode: 'all',
        }),
        CharacterCount,      // .configure({ limit: 1000 }),
        Document,
        Youtube.configure({
          controls: false,
          nocookie: true,
        }),
        Paragraph,
        Text,

        Table.configure({ resizable: true }),
        TableCell,

        TableRow,
        TableCell.configure({
          HTMLAttributes: {
            style: 'background-color: white; border: 1px solid #000; padding: 5px; text-align: center;',
          },
        }),
        TableHeader.configure({
          HTMLAttributes: {
            style: 'background-color: blue; border: 1px solid #000; padding: 5px; text-align: center;',
          },
        }),

        Blockquote,
        Dropcursor,
        TaskList,
        TaskItem.configure({ nested: true }),
        Color,
        FontFamily.configure({
          types: ['textStyle'],
        }),
        BubbleMenu.configure({
          pluginKey: 'bubble-menu',
          element: this.$refs.bubbleMenu,
        }),
      ],
      autofocus: true,
      content: '',
      editorProps: {
        attributes: {
          spellcheck: 'true',
        },
      },
      onUpdate: ({ editor }) => {
      this.characterCount = editor.storage.characterCount.characters();
    }
    });
    this.refreshFiles(); // Load files and folders on mount
  },
  beforeDestroy() {
    if (this.websocket) {
      this.websocket.send(
        JSON.stringify({
          action: "leave",
          room: "documents",
          user: {
            id: this.$store.state.user.id,
            name: this.$store.state.user.firstname + " " + this.$store.state.user.surname,
            avatar: this.$store.state.user.avatar,
            company: this.$store.state.currentOrg.name,
          },
        })
      );
      this.websocket.close();
    }
    if (this.editor) {
      this.editor.destroy();
    }
  },
  unmounted() {
    if (this.websocket) {
      this.websocket.send(
        JSON.stringify({
          action: "leave",
          room: "documents",
          user: {
            id: this.$store.state.user.id,
            name: this.$store.state.user.firstname + " " + this.$store.state.user.surname,
            avatar: this.$store.state.user.avatar,
            company: this.$store.state.currentOrg.name,
          },
        })
      );
      delete this.websocket.onmessage;
    }
    if (this.editor) {
      this.editor.destroy();
    }
  }
};
</script>

<style scoped>

.editor-toolbar button.is-active {
  background: #ccc;
}

/* Table cell style */
.ProseMirror table td,
.ProseMirror table th {
  background-color: yellow !important;
  border: 1px solid #000; /* Optional: Adds border to table cells */
  padding: 5px;
  text-align: center;
}
.editor-toolbar button.is-active {
  background: #ccc;
}


.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.thumbnail {
  cursor: pointer;
}
.file-modal .v-card {
  max-width: 95vw;
  max-height: 90vh;
  overflow-y: auto;
}
.file-modal .v-card-title {
  background-color: black;
  color: white;
  padding: 16px;
  font-size: 20px;
}

.file-modal .v-card-title .headline {
  flex-grow: 1;
  text-align: center;
}

.file-modal .v-card-text {
  padding: 16px;
}

.file-modal .v-card-actions {
  padding: 8px 16px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

img {
  max-width: 100%;
  display: block;
  margin: 10px 0;
}

.no-border {
  border: none !important;
}

.file-name {
  color: inherit;
  text-decoration: none;
}

.button-container {
  margin-top: -16px;
  margin-bottom: -16px;
}

.editor-container {
  border: none !important;
  outline: none !important;
  border-radius: 5px;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
}

.editor-toolbar {
  flex-shrink: 0;
  background: black;
  color: white;
  display: flex;
  align-items: center;
  padding: 2px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  overflow: hidden; 
  white-space: nowrap;
  margin-bottom: 10px;
  height: auto;
  min-height: 40px;
  max-height: 60px;
  box-sizing: border-box;
}


.editor-toolbar button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  margin-right: 2px; 
  padding: 4px; 
  display: flex;
  align-items: center;
}

.editor-toolbar button .material-icons {
  font-size: 16px; 
}

.editor-content {
  padding: 10px;
  background: white;
  color: black;
  flex-grow: 1;
  overflow-y: auto;
}

.black-white-modal .v-card {
  background-color: white;
  color: black;
}

.black-white-modal .v-card-title {
  background-color: black;
  color: white;
  padding: 16px;
  font-size: 20px;
}

.black-white-modal .v-card-text {
  padding: 16px;
}

.black-white-modal .v-card-actions {
  padding: 8px 16px;
  background-color: black;
}

.black-white-modal .v-btn--text {
  color: white;
}
.black-text {
  color: black;
}

.editor-toolbar button.is-active {
  background: #ccc; 
}
.editor-toolbar button .material-icons {
  font-size: 18px;
}

.editor .ProseMirror {
  border: none !important; /* Remove any border */
  outline: none !important; /* Remove any outline */
  background: white; /* Ensure the background is white */
}

.editor .ProseMirror-focused {
  border: none !important; /* Remove any border when focused */
  outline: none !important; /* Remove any outline when focused */
}

/* Bubble menu styles  */
.bubble-menu {
  background: black;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  padding: 5px;
  position: absolute;
  z-index: 12;
}
.bubble-menu button {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
}
.bubble-menu button i {
  font-size: 18px;
}

.character-count {
  font-size: 14px;
  color: white;
  margin-right: 20px;
}

.font-family-select {
  min-width: 150px;
}

/* Responsive styles for mobile devices */
@media (max-width: 390px) {
  .editor-toolbar {
    flex-direction: column;
    height: auto;
  }

  .editor-toolbar button {
    margin-bottom: 2px; /* Reduced margin */
    margin-right: 0;
    width: 100%; /* Full width on small screens */
    justify-content: center;
  }

  .editor-toolbar button .material-icons {
    font-size: 16px; /* Adjusted for mobile */
  }
}

@media (max-width: 414px) {
  .editor-toolbar {
    flex-direction: column;
    height: auto;
  }

  .editor-toolbar button {
    margin-bottom: 2px; /* Reduced margin */
    margin-right: 0;
    width: 100%; /* Full width on small screens */
    justify-content: center;
  }

  .editor-toolbar button .material-icons {
    font-size: 16px; /* Adjusted for mobile */
  }
}


@media (max-width: 600px) { /* Adjust the max-width value based on your needs */
  .editor-toolbar {
    overflow-x: auto; /* Allows horizontal scrolling on smaller screens */
    flex-direction: row;
  }

  .editor-toolbar button {
    margin-bottom: 2px; /* Reduced margin */
    margin-right: 0;
    width: 100%; /* Full width on small screens */
    justify-content: center;
  }

  .editor-toolbar button .material-icons {
    font-size: 16px; /* Adjusted for mobile */
  }
}

</style>